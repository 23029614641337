<template>
  <ValidationObserver ref="form">
    <form  @submit.prevent="loginStep">
      <div class="col-md-12 mb-2 mt-3" id="orderKeyCont">
        <label class="label_default mb-0">
          Digite sua
          <strong>Senha</strong>
        </label>
      </div>
      <ValidationProvider
        v-slot="{ errors, ariaMsg, classes }"
        rules="required|min:5"
        name="Senha"
        tag="div"
        :bails="false"
        class="mb-3"
        :disabled="disabled"
      >
        <div style="margin-bottom:10px;" id="msg" class="d-none">
          <span style="font-size: 14px;" class="alerta"
            >O Caps-Lock está ativado!</span
          >
        </div>

        <input
          :class="classes"
          type="password"
          class="px-2 py-2 input_default form-control"
          placeholder="****"
          v-model="senha"
          :disabled="disabledInputPassword"
          id="txtSenha"
        />

        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>
      <div>
        <button
          type="submit"
          class="btn_default px-2 py-2"
          :disabled="disabled"
          id="buttonSubmitLogin"
        >
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
            v-show="disabled"
          ></span>
          {{ !disabled ? "Entrar" : "" }}
        </button>
      </div>
      <div class="col-md-12 my-1">
        <p class="esqueci_senha">
          Esqueceu a senha?
          <a href="#" @click.prevent="esqueciSenha()">Clique aqui.</a>
        </p>
      </div>
      <div class="routeCont">
        <router-link :to="'/unidadeCpf'" class="outra-conta" v-if="this.sizeWindow < 768">
            {{$route.path !='/logout' ?'Não é você?' :'Ficou com saudades?'}}
            <strong>{{$route.path !='/logout' ?'Entre com outra conta.' : 'Entre novamente aqui.'}}</strong>
        </router-link>
      </div>     
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  data() {
    return {
      senha: "",
      disabled: false,
      disabledInputPassword: true,
      mensagem: "",
      sizeWindow: 0
    };
  },
  mounted() {
    this.sizeWindow = $(window).width();
    $("#txtSenha").keyup(function(e) {
      var key = e.charCode || e.keyCode;
      var self = this;

      //enter, caps lock e backspace não interessam
      //if (key == 13 || key == 8 || key == 46 || key == 20) {
      //  return false;
      // }

      //pega o último caracter digitado
      var tamanho = this.value.length;
      var ultimo_caracter = this.value.substring(tamanho - 1);
      var maiuscula = this.mensagem;

      if (tamanho == 0) {
        $("#msg").addClass("d-none");
        return false;
      }

      if (key == 20 && tamanho != 0) {
        $("#msg").toggleClass("d-none");
        return false;
      }

      //Verifica se é maiúsculo, e se não é shift
      if (
        ultimo_caracter.toUpperCase() == ultimo_caracter &&
        ultimo_caracter.toLowerCase() != ultimo_caracter
      ) {
        $("#msg").removeClass("d-none");
      }
    });
  },
  methods: {
    esqueciSenha() {
      this.$loading();
      this.$store.dispatch("gerateReset").then(resolve => {
        this.$loading.close();
        if (resolve) {
          // this.$emit(
          //   "loginStep",
          //   "_Esqueci_senha",
          //   "linear-gradient(to bottom, #e0a638 0%, #f38235 100%)"
          // );
          this.$router.push("/esqueci-a-senha");
        }
      });
    },
    loginStep() {
      this.$refs.form.validate().then(success => {
        if (success) {
          this.disabled = true;
          this.$store.dispatch("gerateToken", this.senha).then(resolve => {
            if (!resolve.error) {
              this.disabled = false;

              this.$parent.gratientAnimator(
                "linear-gradient(to right, #e0a638 0%,#f38235 100%)"
              );
              this.$store.dispatch("getServices");
              this.$store.dispatch("getSuporte");
              this.$store.commit("SET_TIME_START", new Date().getTime());
              if (this.$store.getters.getUser.profile != 3) {
                this.$router.push("/");
              } else {
                this.$router.push("/abrir-caixa");
              }
            } else {
              this.toast(`${resolve.message}`, this.$toast.error)
              this.disabled = false;
            }
          });
        }
      });
    }
  },
  computed: {
    user() {
      return this.$store.state.login.userLogin;
    }
  }
};
</script>

<style scoped>
</style>
